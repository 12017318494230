@font-face{
    font-family: 'Book';
    src: url('./Book.ttf') format('truetype');
}
@font-face{
    font-family: 'Gochi';
    src: url('./Gochi.ttf') format('truetype');
}
@font-face{
    font-family: 'Move';
    src: url('./Move.ttf') format('truetype');
}
@font-face{
    font-family: 'Phosphate';
    src: url('./Phosphate.ttf') format('truetype');
}
@font-face{
    font-family: 'Heavy';
    src: url('./Heavy.ttf') format('truetype');
}